import React, { useState,useEffect,useCallback } from "react";
import "../assests/styles/PepCurrentHivInformation.css";
import "../assests/styles/request-lab-service.css";
import { StepProps } from "components/RequestLabService/stepsProps";
import DeliveryDateTime from "routes/patient/DeliveryDateTime/DeliveryDateTime";
import useHivCare from "views/patients/pages/HivLabService/useHivCare";
import { useDispatch,useSelector } from "react-redux";
import FacilitySelector from "views/patients/pages/FacilitySelector/FacilitySelector";
import ConsentDatause from "./RequestLabServiceComponents/ConsentDatause";
import { DateInput } from "./InputComponents/InputComponents";
import TimeInput from "./RequestLabService/TimeInput";
import ErrorComponent from "views/patients/pages/ErrorComponent/ErrorComponent";
import AdditionalInformation from "./RequestLabServiceComponents/AdditionalInformation";
const YourARTRequestDetails: React.FC<StepProps> = ({ onNext, onPrevious }) => {
  const PrepRequest = useSelector(
    (state: any) => state.hivCare.Antiretroviral_Therapy

  ); 

  const [preferredRegimen, setPreferredRegimen] = useState<string>("");
  const [otherRegimen, setOtherRegimen] = useState<string>("");
  const [dosageDuration, setDosageDuration] = useState<string>(PrepRequest.prefered_dosage_duration?PrepRequest.prefered_dosage_duration:"");
  const [otherDosageDuration, setOtherDosageDuration] = useState<string>("");
  const [deliveryMethod, setDeliveryMethod] = useState<string>(PrepRequest.delivery_method?PrepRequest.delivery_method:"");
  const [facilityRelationship, setFacilityRelationship] = useState<string>("");
  const [showOtherCheck,setOtherCheck] = useState<string>('')
  const [facility_id, SetFacilityId] = useState<number>(PrepRequest.facility_id);
  const [enabled, setEnabled] = useState<boolean>(false);
  const [otherFacilityRelationship, setOtherFacilityRelationship] =
    useState<string>("");
    const [errors, setErrors] = useState({
      facility: false,
      deliveryMethod: false,
      deliveryDate: false,
      deliveryTime: false,
     dosageDuration: false,

    });
    const [facilityId, setFacilityId] = useState<string>(PrepRequest.facility_id?PrepRequest.facility_id :"");
    const [date, setDate] = useState<string>();
    const [time, setTime] = useState<string>("");
    const [additional_info, setAdditionalInfo] = useState<string>()
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { GetArtTreatment } = useHivCare();
  const handleRegimenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPreferredRegimen(e.target.value);
    if (e.target.value !== "Other") {
      setOtherRegimen("");
    }
  };

  const handleDosageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDosageDuration(e.target.value);
    if (e.target.value !== "Other") {
      setOtherDosageDuration("");
    }
  };

  const handleFacilityRelationshipChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFacilityRelationship(e.target.value);
    if (e.target.value !== "Other") {
      setOtherFacilityRelationship("");
    }
  };
  console.log(dosageDuration)
  const validateForm = () => {
    const newErrors = {
      facility: !facilityId,
      deliveryMethod: !deliveryMethod,
      deliveryDate: !date,
      deliveryTime: !time,
      dosageDuration: !dosageDuration,
    };
    setErrors(newErrors);
    
    return Object.values(newErrors).every(error => !error);
  };
  const memoizedArtPreferedDetails = useCallback(() => {
    GetArtTreatment.artPreferedDetails(
        dispatch,
        preferredRegimen,
        dosageDuration,
        deliveryMethod,
        facilityRelationship,
        facilityId,
        date,
        time,
        additional_info
    );
}, [
    dispatch,
    preferredRegimen,
    dosageDuration,
    deliveryMethod,
    facilityRelationship,
    facilityId,
    date,
    time,
    additional_info,

]);
const handleNext = () => {
  setHasSubmitted(true);
  if (validateForm()) {
    memoizedArtPreferedDetails();
    onNext();
  }
};
useEffect(()=>{
  if (hasSubmitted) {
    validateForm();
  }
},[facilityId,deliveryMethod,DeliveryDateTime,date,time,dosageDuration])
console.log({
  preferredRegimen,
      dosageDuration,
      deliveryMethod,
      facilityRelationship,
      facilityId,
      date,
      time,
      additional_info
})
  var displayError = false;
  const setError = () => {
    displayError = false;
    if (
      preferredRegimen === "" &&
      dosageDuration === "" &&
      facilityId === "" &&
      date === "" &&
    facilityRelationship.length ===0
    ) {
      displayError = true;
      return "Please select all the required fields";
    }

    displayError = false;
    return;
  };
  useEffect(() => {
    if (
      preferredRegimen !== "" &&
      facilityId !== "" &&
      time !== null &&
      date !== ""
    ) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  }, [preferredRegimen, date, time,facilityId]);
  return (
    <div className="request-lab-container">
      <h2 className="mhs-h2">Your ART Request Details</h2>
      {/* <div className="section">
        <h3>Preferred ART Regimen</h3>
        <label>
          <input
            type="radio"
            name="preferredRegimen"
            value="Tenofovir + Emtricitabine + Efavirenz (TDF/FTC/EFV)"
            checked={
              preferredRegimen ===
              "Tenofovir + Emtricitabine + Efavirenz (TDF/FTC/EFV)"
            }
            onChange={handleRegimenChange}
          />
          Tenofovir + Emtricitabine + Efavirenz (TDF/FTC/EFV)
        </label>
        <label>
          <input
            type="radio"
            name="preferredRegimen"
            value="Tenofovir + Emtricitabine + Lopinavir/ritonavir (TDF/FTC/LPV/r)"
            checked={
              preferredRegimen ===
              "Tenofovir + Emtricitabine + Lopinavir/ritonavir (TDF/FTC/LPV/r)"
            }
            onChange={handleRegimenChange}
          />
          Tenofovir + Emtricitabine + Lopinavir/ritonavir (TDF/FTC/LPV/r)
        </label>
        <label>
          <input
            type="radio"
            name="preferredRegimen"
            value="Zidovudine + Lamivudine + Nevirapine (AZT/3TC/NVP)"
            checked={
              preferredRegimen ===
              "Zidovudine + Lamivudine + Nevirapine (AZT/3TC/NVP)"
            }
            onChange={handleRegimenChange}
          />
          Zidovudine + Lamivudine + Nevirapine (AZT/3TC/NVP)
        </label>
        <label>
          <input
            type="radio"
            name="preferredRegimen"
            value="Other"
            checked={preferredRegimen === "Other"}
            onChange={handleRegimenChange}
          />
          Other
        </label>
        {preferredRegimen === "Other" && (
          <input
            type="text"
            className="mt-2 block w-full text-sm text-gray-900 border border-white rounded-lg bg-white dark:bg-white dark:border-gray-600 dark:placeholder-stroke"
            value={otherRegimen}
            onChange={(e) => setOtherRegimen(e.target.value)}
            placeholder="Please specify"
          />
        )}
      </div> */}
      <div className="">
        <h3 className="heading3">Preferred Dosage Duration</h3>
       <div className="flex flex-col text-[1rem] font-[500] mb-[1rem] space-y-4">
       <label className="flex items-center gap-2">
          <input
            type="radio"
            name="dosageDuration"
            value="1 month"
            checked={dosageDuration.includes("1 month")}
            onChange={handleDosageChange}
          />
          1 month
        </label>
        <label className="flex items-center gap-2">
          <input
            type="radio"
            name="dosageDuration"
            value="2 months"
            checked={dosageDuration.includes("2 months")}
            onChange={handleDosageChange}
          />
          2 months
        </label>
        <label className="flex items-center gap-2">
          <input
            type="radio"
            name="dosageDuration"
            value="3 months"
            checked={dosageDuration.includes("3 months")}
            onChange={handleDosageChange}
          />
          3 months
        </label>
        <label className="flex items-center gap-2">
          <input
            type="radio"
            name="dosageDuration"
            value="4 months"
            checked={dosageDuration.includes("4 months")}
            onChange={handleDosageChange}
          />
          4 months
        </label>
        <label className="flex items-center gap-2">
          <input
            type="radio"
            name="dosageDuration"
            value="Other"
            checked={dosageDuration.includes("Other")}
            onChange={(e)=>setOtherCheck(e.target.value)}
          />
          Other
        </label>
        {showOtherCheck && (
          <input
            type="text"
            className="mt-2 block w-full text-sm text-gray-900 border border-white rounded-lg bg-white dark:bg-white dark:border-gray-600 dark:placeholder-stroke"
           
            onChange={(e) => setDosageDuration(e.target.value)}
            placeholder="Please specify"
          />
        )}
       </div>
      </div>
      {errors.dosageDuration && <p className="error-message">Please select your preferred dosage duration.</p>}
      <div className="section">
        
        <FacilitySelector  selectedfacilityId={setFacilityId} id={facilityId}/>
        {errors.facility && <p className="error-message">Please select a facility.</p>}
      </div>
      <div className="section">
        <h3 className="heading3">Preferred Delivery Method</h3>
        <div className="flex flex-col text-[1rem] font-[500] mb-[1rem] gap-2">
        <label className="flex items-center gap-2">
          <input
            type="radio"
            name="deliveryMethod"
            value="Home"
            checked={deliveryMethod.includes("Home")}
            onChange={() => setDeliveryMethod("Home")}
            className="form-radio h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
          />
          Home
        </label>
        <label className="flex items-center gap-2">
          <input
            type="radio"
            name="deliveryMethod"
            value="Workplace"
            checked={deliveryMethod.includes("Workplace")}
            onChange={() => setDeliveryMethod("Workplace")}
            className="form-radio h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
          />
          Workplace
        </label>
        <label className="flex items-center gap-2">
          <input
            type="radio"
            name="deliveryMethod"
            value="Health Facility"
            checked={deliveryMethod.includes("Health Facility")}
            onChange={() => setDeliveryMethod("Health Facility")}
            className="form-radio h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
          />
          Health Facility
        </label>
        </div>
      </div>
      {errors.deliveryMethod && <p className="error-message">Please select a delivery method.</p>}
      <div>
        <DeliveryDateTime  selectedDate={PrepRequest.preferred_date}
        selectedTime={PrepRequest.preferred_time}  setDate={setDate} setTime={setTime}/>
      </div>
      {/* <div className="section">
        <h3>Facility Relationship Status</h3>
        <label>
          <input
            type="radio"
            name="facilityRelationship"
            value="Returning client"
            checked={facilityRelationship === "Returning client"}
            onChange={handleFacilityRelationshipChange}
          />
          Returning client
        </label>
        <label>
          <input
            type="radio"
            name="facilityRelationship"
            value="New client"
            checked={facilityRelationship === "New client"}
            onChange={handleFacilityRelationshipChange}
          />
          New client
        </label>
        <label>
          <input
            type="radio"
            name="facilityRelationship"
            value="Other"
            checked={facilityRelationship === "Other"}
            onChange={handleFacilityRelationshipChange}
          />
          Other
        </label>
        {facilityRelationship === "Other" && (
          <input
            type="text"
            className="mt-2 block w-full text-sm text-gray-900 border border-white rounded-lg bg-white dark:bg-white dark:border-gray-600 dark:placeholder-stroke"
            value={otherFacilityRelationship}
            onChange={(e) => setOtherFacilityRelationship(e.target.value)}
            placeholder="Please specify"
          />
        )}
      </div> */}
      <div className="mb-8">
        <h3 className="add_info ">Additional Information</h3>
        <AdditionalInformation setData={setAdditionalInfo} value={additional_info} />
      </div>
      <div className="flex gap-4">
        <button type="submit" className="submit-button" onClick={onPrevious}>
        Back
        </button>
        <button
          type="submit"
          className="submit-button"
          onClick={handleNext}
          
        >
          Next
        </button>
      </div>
      <div className="mt-4">
  
      </div>
    </div>
  );
};

export default YourARTRequestDetails;