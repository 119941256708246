import { useState, useEffect } from "react";
import InputField from "./inputField";

import { FaRegPlusSquare, FaMinus } from "react-icons/fa";
import useUserInfoForm from "hooks/useUserInfoForm";
import ValidationTitle from "components/shared/validation";
import RequestService from "services/requestService";
import localStorageService from "services/localStorageService";
import { setIsAuthenticated, setUserInfo } from "Features/auth/auth.slice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "../../../../Store";
import SelectInputField from "components/shared/selectInput";
import { NameProp } from "hooks/types";
import PhoneInputComponent from "components/shared/PhoneInputComponent";
import BirthdayForm from "./BirthdayForm";
import Loader from "components/shared/Loader";
// import DropdownCheckbox from "components/DropdownCheckbox";
// import RecentAllergies from "components/shared/RecentAllergies";
import ToastMessage from "components/shared/ToastMesaage";

const MorePatientInfoForm = () => {
  const {
    handleChange,
    validateuseUserInfoForm,
    errors,
    addInputField,
    removeInputField,
    handleMulipleChange,
    useUserInfoFormValues,
    setuseUserInfoFormErrors,
    setFormSubmitStatus,
    fillFormValuesInEditMode,
  } = useUserInfoForm();
  const [isSubmitting, setisSubmitting] = useState<boolean>(false);
  const userInfoData: any = useSelector(
    (state: RootState) => state.auth.userInfo
  );

  const [toast, setToast] = useState({
    status: "",
    message: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitData = async () => {
    const status = validateuseUserInfoForm();

    if (!status) {
      return;
    }

    setFormSubmitStatus(true);
    setisSubmitting(true);
    const formData = {
      ...useUserInfoFormValues,
      stage: 2,
      id: userInfoData?.id,
      "medication[]": JSON.stringify(useUserInfoFormValues.medication),
      "allergies[]": JSON.stringify(useUserInfoFormValues.allergies),
    };
    RequestService.postMethod("save-user-info", formData)
      .then(async (response) => {
        const responseData = { ...userInfoData, ...response.data.responseData };

        dispatch(setUserInfo(responseData));
        await localStorageService.setLocalStorageData("user", responseData);

        setFormSubmitStatus(false);
        dispatch(setIsAuthenticated(true));
        setisSubmitting(false);
        setisSubmitting(true);
        navigate("/dashboard");
        setToast({
          ...toast,
          ...{ status: "success", message: "Information updated" },
        });
      })
      .catch((error) => {
        setFormSubmitStatus(false);
        const errorData = error?.response?.data;
        setisSubmitting(false);
        if (errorData?.responseData) {
          setuseUserInfoFormErrors("e_email", errorData?.responseData);
        }
        if (errorData?.errors) {
          setErrorResponse(errorData.errors);
        }
        // setToast({...toast, ...{status:'error', message:'Something went wrong, please try'}})
      });
  };

  const setErrorResponse = (errors: any) => {
    for (let key in errors) {
      setuseUserInfoFormErrors(key, errors[key][0]);
    }
  };

  useEffect(() => {
    if (userInfoData && userInfoData.first_name) {
      const medication = userInfoData.medication
        ? JSON.parse(userInfoData.medication)
        : [{ id: "medication0", value: "" }];
      const allergies = userInfoData.allergies
        ? JSON.parse(userInfoData.allergies)
        : [{ id: "allergies0", value: "" }];

      //console.log(userInfoData, 'mundivan')
      fillFormValuesInEditMode({
        ...userInfoData,
        ...{ medication, allergies },
      });
      return;
    }
  }, [userInfoData]);

  return (
    <div className="w-full h-screen p-0 md:p-4 mx-auto max-w-[82%] md:max-w-[36rem]">
      <ToastMessage status={toast.status} message={toast.message} />
      <div className="flex flex-col gap-5">
        <h3 className="text-[25px] font-[600] md:text-[34px]">
          Help us to know you more
        </h3>
        {/* <p
          onClick={() => navigate("/")}
          className="text-primary_color cursor-pointer text-[15px] font-[400]"
        >
          Maybe later
        </p> */}
      </div>
      <div>
        <div className="flex justify-between items-center mt-4">
          <h2 className="text-[20px] font-[500]">Personal Information</h2>
          {/* <span>
            <IoIosArrowUp />
          </span> */}
        </div>
        <div>
          <InputField
            value={useUserInfoFormValues.first_name}
            title="First name"
            placeholder="Florence"
            handleChange={handleChange}
            type="text"
            name="first_name"
            uniqueKey="first_name"
          />
          {errors.first_name && <ValidationTitle title={errors.first_name} />}
          <InputField
            value={useUserInfoFormValues.last_name}
            title="Last Name"
            placeholder="Williams"
            handleChange={handleChange}
            type="text"
            name="last_name"
            uniqueKey="last_name"
          />
          {errors.last_name && <ValidationTitle title={errors.last_name} />}

          {/* Replacing the existing date_of_birth field with the BirthdayForm component */}
          <div>
            <label className="text-[15px] md:text-[18px] topographyText font-[400]">
              Date of Birth
            </label>

            <BirthdayForm
              handleChange={(value: string) =>
                handleChange({ target: { name: "date_of_birth", value } })
              }
            />
          </div>
          {errors.date_of_birth && (
            <ValidationTitle title={errors.date_of_birth} />
          )}

          {/* <SelectInputField
            value={useUserInfoFormValues.gender}
            title="Gender"
            placeholder="Florence"
            handleChange={handleChange}
            data={[
              { name: 'Male', value: 'M' },
              { name: 'Female', value: 'F' },
            ]}
            name="gender"
            className="w-[calc(100%-0px)]"
          />

          {errors.gender && <ValidationTitle title={errors.gender} />} */}

          <SelectInputField
            value={useUserInfoFormValues.gender}
            title="Gender"
            placeholder="Select Gender"
            handleChange={handleChange}
            data={[
              { name: "Male", value: "M" },
              { name: "Female", value: "F" },
              { name: "Transman", value: "TM" },
              { name: "Transwoman", value: "TW" },
              { name: "Others (Specify)", value: "O" },
            ]}
            name="gender"
            className="w-[calc(100%-0px)]"
          />

          {/* Render input field for "Others (Specify)" when the selected gender is "Others" */}
          {useUserInfoFormValues.gender === "O" && (
            <InputField
              value={useUserInfoFormValues.otherGender}
              title="Please specify"
              placeholder="Enter your gender"
              handleChange={handleChange}
              type="text"
              name="otherGender"
              uniqueKey="otherGender"
            />
          )}

          {errors.gender && <ValidationTitle title={errors.gender} />}

          <div>
            <label className="text-[15px] md:text-[18px] topographyText font-[400]">
              Phone Number
            </label>
            <PhoneInputComponent
              style={{
                height: "52px",
                borderWidth: "1px",
                borderColor: "#d4d7e3",
              }}
              name="phone_number"
              value={useUserInfoFormValues.phone_number}
              onChange={(value: string) =>
                handleChange({ target: { name: "phone_number", value } })
              }
              error={errors.phone_number} // Pass error message here
            />
            {errors.phone_number && (
              <ValidationTitle title={errors.phone_number} />
            )}
          </div>
          {/* <InputField
            value={useUserInfoFormValues.whatsap_number}
            title="WhatsApp Number"
            placeholder="+256"
            handleChange={handleChange}
            type="text"
            name="whatsap_number"
            uniqueKey="whatsap_number"
          /> */}
          <div className="mt-3">
            <label className="text-[15px] md:text-[18px] topographyText font-[400]">
              WhatsApp Number
            </label>
            <PhoneInputComponent
              name="whatsap_number"
              value={useUserInfoFormValues.whatsap_number}
              onChange={(value: string) =>
                handleChange({ target: { name: "whatsap_number", value } })
              }
              error={undefined} // Pass undefined or an empty value if not needed
            />
            {errors.whatsap_number && (
              <ValidationTitle title={errors.whatsap_number} />
            )}{" "}
            {/* Handle error rendering here */}
          </div>
          {/* <InputField
            value={useUserInfoFormValues.population_group}
            title="Key Population"
            placeholder="Female"
            handleChange={handleChange}
            type="text"
            name="population_group"
            uniqueKey="population_group"
          />
          {errors.population_group && (
            <ValidationTitle title={errors.population_group} />
          )} */}
        </div>
      </div>
      {/* <div>
        <div className="flex justify-between items-center mt-4">
          <h2 className="text-[20px] font-[500]">Address Information</h2>
        </div>
        <div>
          <InputField
            value={useUserInfoFormValues.country}
            title="Country"
            placeholder="Uganda"
            handleChange={handleChange}
            type="text"
            name="country"
            uniqueKey="country"
          />
          {errors.country && <ValidationTitle title={errors.country} />}
          <InputField
            value={useUserInfoFormValues.district}
            title="District"
            placeholder="Kampala"
            handleChange={handleChange}
            type="text"
            name="district"
            uniqueKey="district"
          />
          {errors.district && <ValidationTitle title={errors.district} />}
          <InputField
            value={useUserInfoFormValues.sub_county}
            title="Sub County"
            placeholder="Ggaba"
            handleChange={handleChange}
            type="text"
            name="sub_county"
            uniqueKey="sub_county"
          />
          {errors.sub_county && <ValidationTitle title={errors.sub_county} />}
          <InputField
            value={useUserInfoFormValues.village}
            title="Village"
            placeholder="Ggaba water"
            handleChange={handleChange}
            type="text"
            name="village"
            uniqueKey="village"
          />
          {errors.village && <ValidationTitle title={errors.village} />}
        </div>
      </div> */}
      {/* <div>
        <div className="flex justify-between items-center mt-4">
          <h2 className="text-[20px] font-[500]">Medical Conditions</h2>
          
        </div>
        <div>
          <p>Medications</p>
          <div>
            {useUserInfoFormValues.medication.map((inputField, index) => (
              <div
                key={inputField.id}
                className="flex items-center justify-between w-full"
              >
                <div className="w-[95%]">
                  <InputField
                    value={useUserInfoFormValues.medication[index].value}
                    title=""
                    placeholder=""
                    uniqueKey={inputField.id + "inputfield"}
                    handleChange={(e) =>
                      handleMulipleChange(e, index, NameProp.medication)
                    }
                    type="text"
                    name="medication"
                  />
                </div>
                {useUserInfoFormValues.medication.length === index + 1 ? (
                  <div className="cursor-pointer ml-2">
                    <FaRegPlusSquare
                      size="30"
                      color="#ccc"
                      onClick={() => addInputField(NameProp.medication)}
                    />
                  </div>
                ) : (
                  <div className="cursor-pointer ml-2">
                    <FaMinus
                      size="30"
                      color="#ccc"
                      onClick={() =>
                        removeInputField(NameProp.medication, index)
                      }
                    />
                  </div>
                )}
              </div>
            ))}
            
          </div>
          <p>Allergies</p>
          <div>
            {useUserInfoFormValues.allergies.map((inputField, index) => (
              <div
                key={inputField.id}
                className="flex items-center justify-between w-full"
              >
                <div className="w-[95%]">
                  <InputField
                    value={useUserInfoFormValues.allergies[index].value}
                    title=""
                    placeholder=""
                    uniqueKey={inputField.id + "inputfield"}
                    handleChange={(e) =>
                      handleMulipleChange(e, index, NameProp.allergies)
                    }
                    type="text"
                    name="allergies"
                  />
                </div>
                {useUserInfoFormValues.allergies.length == index + 1 ? (
                  <div className="cursor-pointer ml-2">
                    <FaRegPlusSquare
                      size="30"
                      color="#ccc"
                      onClick={() => addInputField(NameProp.allergies)}
                    />
                  </div>
                ) : (
                  <div className="cursor-pointer ml-2">
                    <FaMinus
                      size="30"
                      color="#ccc"
                      onClick={() =>
                        removeInputField(NameProp.allergies, index)
                      }
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div> */}
      {/* <div>
        <div className="flex justify-between items-center">
          <h2 className="text-[20px] font-[500] topographyText">
            Emergency Contact Information
          </h2>
          
        </div>
        <div>
          <InputField
            value={useUserInfoFormValues.e_full_name}
            title="Full Name"
            placeholder="Uganda"
            uniqueKey="e_full_name"
            handleChange={handleChange}
            type="text"
            name="e_full_name"
          />
          {errors.e_full_name && <ValidationTitle title={errors.e_full_name} />}

          <InputField
            value={useUserInfoFormValues.e_relationship}
            title="Relationship"
            placeholder="father"
            handleChange={handleChange}
            type="text"
            uniqueKey="e_relationship"
            name="e_relationship"
          />

          {errors.e_relationship && (
            <ValidationTitle title={errors.e_relationship} />
          )}

          <PhoneInputComponent
            name="e_phone_number"
            value={useUserInfoFormValues.e_phone_number}
            onChange={(value: string) =>
              handleChange({ target: { name: "e_phone_number", value } })
            }
            error={undefined} 
          />
          {errors.e_phone_number && (
            <ValidationTitle title={errors.e_phone_number} />
          )}
          <InputField
            value={useUserInfoFormValues.e_email}
            title="Email Adress"
            placeholder="san12@gmail.com"
            handleChange={handleChange}
            type="text"
            name="e_email"
            uniqueKey="e_email"
          />
          {errors.e_email && <ValidationTitle title={errors.e_email} />}
        </div>
      </div> */}
      <button  style={{marginTop: '3%'}}
        onClick={submitData}
        className="bg-primary_color w-full h-[50px] flex items-center justify-center text-white rounded-lg "
      >
        {isSubmitting ? (
          <div className="flex justify-center items-center w-full h-full m-0">
            <Loader />
          </div>
        ) : (
          "Save"
        )}
      </button>
      <div className="h-16"></div> {/* Spacer div */}
    </div>
  );
};

export default MorePatientInfoForm;
