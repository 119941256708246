import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import useHivCare from "views/patients/pages/HivLabService/useHivCare";
import "../assests/styles/PepCurrentHivInformation.css";
import "../assests/styles/request-lab-service.css";
import { StepProps } from "components/RequestLabService/stepsProps";
import DeliveryDateTime from "routes/patient/DeliveryDateTime/DeliveryDateTime";
import FacilitySelector from "views/patients/pages/FacilitySelector/FacilitySelector";
import useStates from "hooks/useStates";
import ConsentDatause from "./RequestLabServiceComponents/ConsentDatause";
import ErrorComponent from "views/patients/pages/ErrorComponent/ErrorComponent";
import { DateInput } from "./InputComponents/InputComponents";
import useRequestLabService from "hooks/useRequestLabService";

const PEPRequestDetails: React.FC<StepProps> = ({ onNext, onPrevious }) => {
  const PrepRequest = useSelector(
    (state: any) => state.hivCare.Pre_Exposure_Prophylaxis
  );
  const { lapServiseState } = useRequestLabService();
  const { LabTestDone } = lapServiseState;
  const { getFacilities_data } = useStates();
  
  const inputRef = useRef<any>();

  // Form states
  const [selectedFile, setSelectedFile] = useState<File | null>(
    PrepRequest.report_file_uri ? PrepRequest.report_file_uri : null
  );
  const [preferredMedication, setPreferredMedication] = useState<string>("");
  const [deliveryMethod, setDeliveryMethod] = useState<string>(
    PrepRequest.result_delivery_method ? PrepRequest.result_delivery_method : ""
  );
  const [facilityStatus, setFacilityStatus] = useState<string>("");
  const [facility_id, setFacilityId] = useState<number>(PrepRequest.facility_id);
  const [selectedFacilityId, setSelectedFacilityId] = useState<string>(
    PrepRequest.facility_id ? PrepRequest.facility_id : ""
  );
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState<string>(
    PrepRequest.preferred_date ? PrepRequest.preferred_date : ""
  );
  const [selectedDeliveryTime, setSelectedDeliveryTime] = useState<string>(
    PrepRequest.preferred_time ? PrepRequest.preferred_time : ""
  );
  const [testDate, setTestDate] = useState(
    PrepRequest.last_test_date
      ? PrepRequest.last_test_date
      : {
          month: "October",
          day: "03",
          year: "2009",
        }
  );

  // Error states
  const [errors, setErrors] = useState({
    facility: false,
    deliveryMethod: false,
    deliveryDate: false,
    deliveryTime: false,
    testDate: false,
    selectedFiles: false,
  });

  // Tracks if the form has been submitted
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const dispatch = useDispatch();
  const { GetPrepExposeProphylaxis } = useHivCare();

  // Validate the form fields
  const validateForm = useCallback(() => {
    const newErrors = {
      facility: !selectedFacilityId,
      deliveryMethod: !deliveryMethod,
      deliveryDate: !selectedDeliveryDate,
      deliveryTime: !selectedDeliveryTime,
      testDate: !testDate,
      selectedFiles: selectedFile === null,
    };
    setErrors(newErrors);

    return Object.values(newErrors).every((error) => !error);
  }, [
    deliveryMethod,
    selectedFacilityId,
    selectedDeliveryDate,
    selectedDeliveryTime,
    testDate,
    selectedFile,
  ]);

  // Handle the "Next" button click
  const handleNext = () => {
    setHasSubmitted(true); // Set to true when user clicks "Next"
    
    if (validateForm()) {
      // If form is valid, proceed
      GetPrepExposeProphylaxis.PrePRequestDetails(
        dispatch,
        preferredMedication,
        deliveryMethod,
        selectedFacilityId,
        selectedDeliveryDate,
        selectedDeliveryTime,
        testDate,
        selectedFile
      );
      onNext();
    }
  };

  // Handle field change and reset specific error
  const handleFieldChange = (field: string, value: any) => {
    // Reset error for the field when user interacts
    setErrors((prevErrors) => ({ ...prevErrors, [field]: false }));
    
    switch (field) {
      case "facility":
        setSelectedFacilityId(value);
        break;
      case "deliveryMethod":
        setDeliveryMethod(value);
        break;
      case "deliveryDate":
        setSelectedDeliveryDate(value);
        break;
      case "deliveryTime":
        setSelectedDeliveryTime(value);
        break;
      case "testDate":
        setTestDate(value);
        break;
      case "selectedFiles":
        setSelectedFile(value);
        break;
      default:
        break;
    }
  };

  return (
    <div className="request-lab-container">
      <h3 className="heading2">Your PreP Request Details</h3>
      
      <FacilitySelector
        selectedfacilityId={(value: string) => handleFieldChange("facility", value)}
        id={selectedFacilityId}
      />
      {hasSubmitted && errors.facility && (
        <p className="error-message">Please select a facility.</p>
      )}

      <div className="section">
        <DateInput
          selectedTestDate={PrepRequest.last_test_date}
          setDate={(value: any) => handleFieldChange("testDate", value)}
        />
        {hasSubmitted && errors.testDate && (
          <p className="error-message">Please select your last HIV test date.</p>
        )}
        
        <div className="mt-2">
          <h3 className="heading3">Upload recent HIV Test Report</h3>
          <input
            className="block w-full text-sm border border-white rounded-lg cursor-pointer bg-white focus:outline-none"
            id="file_input"
            type="file"
            onChange={(e) =>
              handleFieldChange("selectedFiles", e.target.files ? e.target.files[0] : null)
            }
          />
          {hasSubmitted && errors.selectedFiles && (
            <p className="error-message">Please select your last HIV report.</p>
          )}
        </div>
      </div>

      <h3 className="heading3">Preferred Delivery Method</h3>
      <div className="flex flex-col text-[1rem] font-[500] mb-[1rem] space-y-4">
        <label className="flex items-center space-x-3">
          <input
            type="radio"
            name="deliveryMethod"
            value="Home"
            checked={deliveryMethod === "Home"}
            onChange={() => handleFieldChange("deliveryMethod", "Home")}
            className="form-radio h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
          />
          <span className="text-gray-700">Home</span>
        </label>
        <label className="flex items-center space-x-3">
          <input
            type="radio"
            name="deliveryMethod"
            value="Workplace"
            checked={deliveryMethod === "Workplace"}
            onChange={() => handleFieldChange("deliveryMethod", "Workplace")}
            className="form-radio h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
          />
          <span className="text-gray-700">Workplace</span>
        </label>
        <label className="flex items-center space-x-3">
          <input
            type="radio"
            name="deliveryMethod"
            value="Health Facility"
            checked={deliveryMethod === "Health Facility"}
            onChange={() => handleFieldChange("deliveryMethod", "Health Facility")}
            className="form-radio h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
          />
          <span className="text-gray-700">Health Facility</span>
        </label>
      </div>
      {hasSubmitted && errors.deliveryMethod && (
        <p className="error-message">Please select a delivery method.</p>
      )}

      <DeliveryDateTime
        setTime={(value: string) => handleFieldChange("deliveryTime", value)}
        setDate={(value: string) => handleFieldChange("deliveryDate", value)}
        selectedDate={PrepRequest.preferred_date}
        selectedTime={PrepRequest.preferred_time}
      />
      {hasSubmitted && errors.deliveryDate && (
        <p className="error-message">Please select a delivery date.</p>
      )}
      {hasSubmitted && errors.deliveryTime && (
        <p className="error-message">Please select a delivery time.</p>
      )}

      <div className="flex gap-4">
      <button type="submit" className="submit-button" onClick={onPrevious}>
          Back
        </button>
        <button type="submit" className="submit-button" onClick={handleNext}>
          Next
        </button>
      </div>
    </div>
  );
};

export default PEPRequestDetails;
