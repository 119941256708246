

const domain = 'https://netlifeapi.tallen.tech/public/api/patient/';
//const domain = 'https://netlifeapi.netlife.cc/backend/public/api/patient/'
// const domain = 'http://127.0.0.1:8000/api/patient/'


const patientConstants = {

    api_domain: domain
}


export default patientConstants;
